import React from "react";
import { Layout } from "../components";
import * as PropTypes from "prop-types";
import { interval, snakeMain } from "../utils/snake";

const siteMetadata = require("../../site-metadata.json");

const pageContext = {
  frontmatter: {
    title: "Snake",
    subtitle: "Snake",
    template: "post",
    noindex: false,
    canonical_url: undefined,
    ...siteMetadata,
    ...siteMetadata.header,
  },
  site: {
    siteMetadata: {
      favicon: "images/favicon.png",
      title: "metaTitle",
      ...siteMetadata,
      ...siteMetadata.header,
    },
  },
  url: "/snake/",
};

export default class Snake extends React.Component {
  componentDidMount() {
    snakeMain();
  }

  componentWillUnmount() {
    if (interval) {
      clearInterval(interval);
    }
  }

  render() {
    let { data } = this.props;
    return (
      <Layout
        hideScroller={true}
        pageContext={{
          ...data,
          ...pageContext,
          pageContext: pageContext,
        }}
      >
        <div className={"post post-full"}>
          <div className="post-content inner-sm">
            <canvas id="mainCanvas" width="376" height="302" />
          </div>
          <div className="post-content inner-sm">
            Use arrow keys to move. Or swipe in the game window on mobile.
          </div>
        </div>
      </Layout>
    );
  }
}

Snake.propTypes = { data: PropTypes.any };
